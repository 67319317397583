<template src='./referral.html'>   </template>
<style scoped src="./referral.css"></style>

<script>
import HomeService from '@/services/HomeService'
import Loading from 'vue-loading-overlay'
import chart from '@/components/chart/chart'
import modal from '@/components/modal/notification/modal'
import pagination from '@/components/vtable/vtpagination.vue'
import AppConstants from '../../constants/AppConstants.vue'

export default {
    data () {
        return {
            customerEmail : '',
            toDate: '',
            fromDate : '',
            columns: ['ReferrerName', 'ReferrerEmail', 'RefereeName', 'RefereeEmail', 'IsSignedUp','TransactionRefNumber','FirstTransactionDate'],
            options: {
                headings: {
                    ReferrerName : 'Referrer Name',
                    ReferrerEmail : 'Referrer Email',
                    RefereeName : 'Referee Name',
                    RefereeEmail : 'Referee Email',
                    TransactionRefNumber : 'Transaction RefNumber',
                    FirstTransactionDate : 'First TransactionDate'                                       
                }
            },
            referralData : [],
            loaderVisible : false,
            year : [],
            selectedYear:'',
            Key : 0,
            dataList : [],
            chartData : {
                labels: ['Jan', 'Feb','Mar','Apr','May','Jun','July','Aug','Sep','Oct','Nov','Dec'],
                datasets: [
                    {
                        label: 'Signed Up',
                        backgroundColor: '#f87979',
                        data : []
                    },
                    {
                        label: 'Transaction Made',
                        backgroundColor: '#f87934',
                        data: []
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            },
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            isLazyLoadingEnabled: AppConstants.enableLazyLoading
        }
    },
    methods : {
        async onShowReferrals () {
            try {
                this.referralData = []
                this.loaderVisible = true
                const mapReferral = (referal) => {
                    Object.values(referal).map((el, key) => {
                        const Objectkey  = Object.keys(referal)[key] 
                        referal[Objectkey] = Objectkey === 'IsSignedUp' ? el._text === '1' ? 'Yes' : 'No' : el._text
                    })
                }           
                await HomeService.methods.GetReferralDetails(this.customerEmail, this.toDate, this.fromDate)
                .then( res => {
                    let temp = res.csGetReferralsResponse.ReferralItems.csReferralItem
                    if (temp) {
                        temp = Array.isArray(temp) ? temp : [temp]
                        this.referralData = JSON.parse(JSON.stringify(temp))
                        temp.map(mapReferral)
                        let yearList=[]
                        let signUpDate =[]
                        temp.forEach(el => {
                            let tempSignUpDate = {}
                            var temp = el.SignUpDate.split(' ')
                            var date = new Date(temp[0]);
                            var dateYear = date.getFullYear()
                            yearList.push(dateYear)
                            tempSignUpDate.year= dateYear
                            tempSignUpDate.signUp = temp[0]
                            tempSignUpDate.isTransactionMade = el.TransactionId ? '1' : '0'
                            signUpDate.push(tempSignUpDate)
                        })
                        this.year = Array.from(new Set(yearList))
                        this.selectedYear = this.year[0]
                        this.dataList =signUpDate
                        this.showGraph()
                    } else {
                        this.referralData = temp
                        this.year = []
                        this.selectedYear =''
                        this.dataList = []
                        this.showGraph()
                    }
                })
                .catch( err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
                this.loaderVisible = false       
            } catch (error) {
               //
            }     
        },
        showGraph(){
            try {
                let filterSignUpDate = this.dataList.filter(el => el.year === this.selectedYear)
                var signUpData= []
                var transactionData =[]
                filterSignUpDate.forEach(el=> {
                    let date=new Date(el.signUp)
                    if (isNaN(signUpData[date.getMonth()])) {
                        signUpData[date.getMonth()] = 1;
                        transactionData[date.getMonth()] = el.isTransactionMade === "1"? 1 : 0;
                    } else {
                        signUpData[date.getMonth()] += 1;
                        transactionData[date.getMonth()] += el.isTransactionMade === "1"? 1 : 0 ;
                    }
                })
                for (let i = 0 ; i < signUpData.length ;i++) {
                    if(!signUpData[i]){
                        signUpData[i] = '0'
                    }
                }
                for (let i = 0 ; i < transactionData.length ;i++) {
                    if(!transactionData[i]){
                        transactionData[i] = '0'
                    }
                }
                this.chartData.datasets[0].data = signUpData
                this.chartData.datasets[1].data = transactionData
                this.Key++
            } catch (error) {
                //
            }
        },
        onClear () {
            this.customerEmail = '',
            this.toDate = '',
            this.fromDate = ''
        }
    },
    components : {
        Loading,chart,modal, pagination
    },
    computed: {
        referralDataTable() {
            let referalItmes = []
            if (this.referralData && this.referralData.length > 0) {
                referalItmes = JSON.parse(JSON.stringify(this.referralData))
                if (this.isLazyLoadingEnabled) {
                    let selectedPage = this.$refs.paginationRef.selectedPage
                    referalItmes = referalItmes.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                referalItmes.forEach(items => {
                    Object.keys(items).map(key =>{
                        items[key] = items[key]._text ?? null
                        if(key === 'IsSignedUp')
                            items[key] = items[key] === '1' ? 'Yes' : 'No' 
                    })
                })
            }
            const udpatePaginationRecords = () => {
                if (this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.referralData?.length ?? 0
                }
            }
            udpatePaginationRecords()
            return referalItmes
        }
    }
}
</script>